<template>
    <div id="content" class="operational-timer-management">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button
                bType="save"
                :bCallback="saveOperationalTimer"
                customClass="btn-action"
                :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"
            ></Button>
            <Button v-if="$route.name === 'EditOperationalTimers'" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="duplicate" :bCallback="clone" customClass="btn-duplicate" bLabel="menu_aux.action.clone"></Button>
                <Button bType="delete" :bCallback="showDeleteOperationalTimerModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="newOperationalTimer">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <!-- Name and Status -->
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.name') }}</h2>
                        <p v-if="errors.name" class="error-form error-field" v-html="$t('library.form.error_name')"></p>
                        <p v-if="errors.nameTooLong" class="error-form error-field" v-html="$t('tools.operationalTimer.error_nameTooLong')"></p>
                        <div class="user-answer">
                            <TranslateInput
                                :translation_keyname="'operational_timer.' + newOperationalTimerData.id + '.name'"
                                @input="handleInput($event)"
                                v-model="newOperationalTimerData.name"
                                :gOptions="{ mandatory: true }"
                                :_placeholder="$t('tools.operationalTimer.addInputPlaceHolder')"
                                :gMessage="{ error: $t('product.product_state.error_name') }"
                                :gState="errors.name ? 'error' : ''"
                                gSize="m"
                            ></TranslateInput>
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <div class="switch">
                            <h2 class="label-form" v-html="newOperationalTimerData.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()" :class="{ disabled: newOperationalTimerData.status == -2 }">
                                <input
                                    class="switch-input-form"
                                    type="checkbox"
                                    v-model="newOperationalTimerData.status"
                                    disabled="newOperationalTimerData.status == -2"
                                />
                            </div>
                        </div>
                        <p class="description" v-html="$t('tools.operationalTimer.status_description')"></p>
                    </div>
                </div>
                <!-- Description and Location -->
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.describ') }}</h2>
                        <p class="description" v-html="$t('tools.operationalTimer.description_description')"></p>
                        <p class="error-form error-field" v-if="errors.description">{{ $t('tools.operationalTimer.error_description') }}</p>
                        <div class="user-answer">
                            <TranslateInput
                                :translation_keyname="'operational_timer.' + newOperationalTimerData.id + '.description'"
                                @input="updateValues($event)"
                                v-model="newOperationalTimerData.description"
                                :gOptions="{ mandatory: true }"
                                :_placeholder="$t('tools.operationalTimer.addInputPlaceHolder')"
                                :gMessage="{ error: $t('product.product_state.error_name') }"
                                :gState="errors.description ? 'error' : ''"
                                gSize="m"
                            ></TranslateInput>
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <div class="switch">
                            <h2 class="label-form">{{ $t('tools.operationalTimer.ubi') }}</h2>
                            <p v-if="errors.locations" class="error-form error-field" v-html="$t('library.form.error_locations')"></p>
                            <p v-if="errors.groups" class="error-form error-field" v-html="$t('library.form.error_groups')"></p>
                            <div class="switch-click" @click="siteLimitSwitch()">
                                <input class="switch-input-form" type="checkbox" v-model="siteLimit" :class="{ disabled: this.siteLimit == false }" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('tools.operationalTimer.location_description')"></p>
                        <div class="user-answer">
                            <SiteLimit
                                :definedMode="limitmode ? limitmode : ''"
                                v-if="siteLimit"
                                @setSiteLimit="setSiteLimit"
                                :locations="newOperationalTimerData.locations.join(',')"
                                :groups="newOperationalTimerData.groups.join(',')"
                            ></SiteLimit>
                        </div>
                    </div>
                </div>
                <!-- Icon and Color -->
                <div class="label-container">
                    <div class="left-container translate-disable">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.icon') }}</h2>
                        <p class="description" v-html="$t('tools.operationalTimer.icon_description')"></p>
                        <p class="error-form error-field" v-if="errors.icon">{{ $t('tools.operationalTimer.error_icon') }}</p>
                        <div class="user-answer ball-colours">
                            <img
                                class="icon"
                                @click="toggleIconDropdown"
                                :src="newOperationalTimerData.icon || iconUrls[24]"
                                alt="Default Timer Icon"
                                :class="{ active: newOperationalTimerData.icon == iconUrls[24] }"
                            />
                            <div v-if="showAllIcons" class="dropdown">
                                <img
                                    class="icon"
                                    @click="setIcon(iconUrl)"
                                    v-for="(iconUrl, index) in iconUrls"
                                    :src="iconUrl"
                                    :alt="'Timer Icon ' + (index + 1)"
                                    :class="{ active: newOperationalTimerData.icon == iconUrl }"
                                    :key="iconUrl"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="right-container">
                        <h2 class="label-form">{{ $t('product.product_category.color') }}</h2>
                        <p class="description" v-html="$t('tools.operationalTimer.color_description')"></p>

                        <div class="user-answer ball-colours">
                            <div class="square">
                                <span
                                    class="defaultBall"
                                    @click="toggleColorDropdown"
                                    :style="{ backgroundColor: newOperationalTimerData.color || colors[15] }"
                                    :class="{ active: newOperationalTimerData.color == colors[15] }"
                                ></span>
                            </div>

                            <div v-if="showAllColors" class="dropdown">
                                <span
                                    class="ball"
                                    @click="setColor(col)"
                                    v-for="(col, index) in filteredColors"
                                    :style="{ backgroundColor: col }"
                                    :class="{ active: newOperationalTimerData.color == col }"
                                    :key="col"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container translate-disable">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.category') }}</h2>
                        <p class="error-form error-field" v-if="errors.category">{{ $t('tools.operationalTimer.error_category') }}</p>
                        <div class="categorySelect">
                            <v-autocomplete
                                class="categories"
                                v-model="newOperationalTimerData.category"
                                :items="categories"
                                @change="setCategory($event)"
                                item-text="label"
                                item-value="value"
                                :placeholder="$t('tools.operationalTimer.category_description')"
                                solo
                            ></v-autocomplete>
                        </div>
                    </div>
                    <!-- Register -->
                    <div class="right-container translate-disable">
                        <div class="switch">
                            <h2
                                class="label-form"
                                v-html="this.newOperationalTimer.type == 2 ? $t('tools.operationalTimer.register') : $t('tools.operationalTimer.noRegister')"
                            ></h2>

                            <div class="switch-click" @click="registerSwitch" :class="{ disabled: newOperationalTimer.type == 1 }">
                                <input class="switch-input-form" type="checkbox" :checked="newOperationalTimer.type === 2" />
                            </div>
                        </div>
                        <p class="description" v-if="this.newOperationalTimer.type == 1" v-html="$t('tools.operationalTimer.noRegister_description')"></p>
                        <p class="description" v-if="this.newOperationalTimer.type == 2" v-html="$t('tools.operationalTimer.register_description')"></p>
                    </div>
                </div>
                <!-- Order and Config -->
                <div class="label-container">
                    <div class="left-container translate-disable">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.order') }}</h2>
                        <p class="error-form error-field" v-if="errors.order">{{ $t('tools.operationalTimer.error_order') }}</p>
                        <p class="description" v-html="$t('tools.operationalTimer.order_description')"></p>
                        <div class="user-answer">
                            <input
                                type="number"
                                v-model.number="newOperationalTimerData.order"
                                placeholder="0"
                                @input="updateValues($event)"
                                :class="{ error: errors.order }"
                            />
                        </div>
                    </div>
                    <div class="right-container">
                        <h2 class="label-form">{{ $t('tools.operationalTimer.configAlarm') }}</h2>
                        <div>
                            <v-radio-group v-model="selectedTimeType" class="opt-group">
                                <div class="typeRepeat" style="display: flex; align-items: center">
                                    <div class="label" style="flex: 3; margin-right: -0.7rem">{{ $t('tools.operationalTimer.question_time_repeat') }}</div>
                                    <div class="select-container" style="flex: 6; margin-bottom: 0.3rem; width: 40%">
                                        <v-select
                                            hide-details
                                            class="select fixed-width-select"
                                            v-model="type"
                                            :items="periodicityTypes"
                                            :item-text="(item) => $t('template.general.periodicity_times.' + item.text)"
                                            item-value="value"
                                            dense
                                            :no-data-text="$t('supervise.filter.no_results')"
                                            @change="changeType($event)"
                                        ></v-select>
                                    </div>
                                </div>
                                <template v-if="type !== 1">
                                    <div class="daytime">
                                        <div
                                            class="item"
                                            v-for="(day, index) in weekDays"
                                            :key="index"
                                            :class="{
                                                selected: daytimes.includes(parseInt(index))
                                            }"
                                            @click="weekdaySelect(index, daytimes.includes(parseInt(index)))"
                                        >
                                            {{ day.substring(0, 3) }}.
                                        </div>
                                    </div>
                                </template>
                                <div class="option">
                                    <div class="radio">
                                        <v-radio class="radioType1" color="#157d82" :value="1"></v-radio>
                                        <div class="label">
                                            {{ $t('tools.operationalTimer.fixHour') }}
                                        </div>
                                    </div>

                                    <div class="timeType1" v-if="selectedTimeType === 1">
                                        <p class="timeText">{{ $t('tools.operationalTimer.from') }}</p>
                                        <input
                                            class="hour"
                                            type="time"
                                            v-model="from_formatted"
                                            @input="timeframeHoursFormat('start', $event)"
                                            @keydown="controlMinutesKeys($event, 'start')"
                                        />
                                        <p class="timeText">{{ $t('tools.operationalTimer.to') }}</p>
                                        <input
                                            class="hour"
                                            type="time"
                                            v-model="to_formatted"
                                            @input="timeframeHoursFormat('end', $event)"
                                            @keydown="controlMinutesKeys($event, 'end')"
                                        />
                                    </div>
                                    <div class="interval_line" v-if="selectedTimeType === 1">
                                        <p class="timeText">{{ $t('tools.operationalTimer.each') }}</p>
                                        <input class="interval" type="number" v-model="fixedTime.iv" />
                                        <div class="timeUnitsSelect">
                                            <v-autocomplete
                                                class="timeUnits"
                                                v-model="selectedTimeUnit"
                                                :items="timeUnits"
                                                item-text="label"
                                                item-value="value"
                                                :placeholder="$t('tools.operationalTimer.hours')"
                                                solo
                                            ></v-autocomplete>
                                        </div>
                                    </div>
                                </div>
                                <div class="option">
                                    <div class="radio">
                                        <v-radio class="radioType2" color="#157d82" :value="2"></v-radio>
                                        <div class="label">{{ $t('tools.operationalTimer.specialHour') }}</div>
                                    </div>
                                    <div class="timeType2" v-if="selectedTimeType === 2">
                                        <div class="timeType2Input" v-for="(time, index) in this.specialTimesFormated" :key="index">
                                            <div class="atTimeText">{{ $t('tools.operationalTimer.at') }}</div>
                                            <input
                                                type="time"
                                                v-model="specialTimesFormated[index]"
                                                @input="timeframeHoursFormat('start', $event, index)"
                                                @keydown="controlMinutesKeysFormatDifferent($event, index)"
                                            />
                                            <div @click="deleteSpecialTime(index)" class="deleteTime"></div>
                                        </div>
                                        <Button class="addTime" bType="create" :bCallback="addTime" :bLabel="$t('tools.operationalTimer.addTime')"> </Button>
                                    </div>
                                </div>
                            </v-radio-group>
                            <p class="error-form error-field" v-if="errors.times">{{ $t('tools.operationalTimer.error_times') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import SiteLimit from '@/components/ui/SiteLimit'

export default {
    name: 'OperationalTimerForm',
    props: {
        id: {
            type: String,
            required: true
        },
        item: undefined
    },
    components: { SiteLimit },
    data() {
        return {
            weekDays: i18n.t('assets.calendar.day_fullname'),
            monthDays: ['ignore postion 0'].concat(i18n.t('assets.calendar.months')),
            type: 1,
            switchMargin: false,
            daytimes: [],
            position: false,
            error: {
                margin: false
            },
            periodicityTypes: [
                { text: 'daily', value: 1 },
                { text: 'weekly', value: 2 },
                { text: 'biweekly', value: 3 }
            ],
            limitmode: '',
            siteLimit: false,
            selectedSite: '',
            selectedTimeUnit: '',
            selectedTimeType: 1,
            selectedCategory: '',
            times: [{ value: '' }],
            showAllColors: false,
            showAllIcons: false,
            timeUnits: [
                { value: 'hours', label: this.$t('tools.operationalTimer.hours') },
                { value: 'minutes', label: this.$t('tools.operationalTimer.minutes') }
            ],
            sites: [
                { value: 'Groups', label: this.$t('analytics.groups') },
                { value: 'Locations', label: this.$t('analytics.locations') }
            ],
            categories: [],
            sendForm: true,
            errors: {
                name: false,
                nameTooLong: false,
                description: false,
                order: false,
                locations: false,
                times: false
            },
            idOperationalTimer: false,
            form: false,
            colors: extraColors,
            moreOptions: false,
            issueDefault: false,
            iconUrls: [],
            newOperationalTimerData: {
                id: '',
                name: '',
                description: '',
                times: {},
                icon: '',
                order: 0,
                type: 1,
                status: 1,
                locations: [],
                groups: [],
                color: '',
                typeRepeat: '',
                daytimes: [],
                category: ''
            },
            fixedTime: { sd: '', ed: '', iv: '' },
            from_formatted: '',
            to_formatted: '',
            specialTimes: { t: [''] },
            specialTimesFormated: [''],
            from_mode: '',
            to_mode: '',
            type: 1
        }
    },

    computed: {
        newOperationalTimer() {
            if (this.id) {
                const operationalTimer = this.$store.getters['operationalTimers/getOperationalTimers'](this.id)

                if (operationalTimer) {
                    this.newOperationalTimerData = { ...this.newOperationalTimerData }
                    //ID
                    if (operationalTimer.id) {
                        this.newOperationalTimerData.timer_id = operationalTimer.id
                    }
                    //name
                    if (operationalTimer.name) {
                        this.newOperationalTimerData.name = operationalTimer.name
                    }
                    //color
                    if (operationalTimer.color) {
                        this.newOperationalTimerData.color = operationalTimer.color
                    }
                    //description
                    if (operationalTimer.description) {
                        this.newOperationalTimerData.description = operationalTimer.description
                    }
                    //icon
                    if (operationalTimer.icon) {
                        this.newOperationalTimerData.icon = operationalTimer.icon
                    }
                    //id
                    if (operationalTimer.id) {
                        this.newOperationalTimerData.id = operationalTimer.id
                    }
                    // locations
                    if (operationalTimer.locations || operationalTimer.groups) {
                        const locations = operationalTimer.locations ? Object.values(operationalTimer.locations) : []
                        const groups = operationalTimer.groups ? Object.values(operationalTimer.groups) : []

                        if (locations.length > 0 || groups.length > 0) {
                            this.siteLimit = true
                            this.newOperationalTimerData.groups = groups.filter((group) => group !== undefined).map((group) => group.id)
                            this.newOperationalTimerData.locations = locations.filter((location) => location !== undefined).map((location) => location.id)
                        }
                    }

                    //order
                    if (operationalTimer.order) {
                        this.newOperationalTimerData.order = operationalTimer.order
                    }
                    //status
                    if (operationalTimer.status == '-2') {
                        this.newOperationalTimerData.status = 0
                    } else if (operationalTimer.status == '1') {
                        this.newOperationalTimerData.status = 1
                    }
                    if (operationalTimer.id) {
                        this.newOperationalTimerData.id = operationalTimer.id
                    }

                    // periodicity

                    if (operationalTimer.periodicity) {
                        const periodicity = JSON.parse(operationalTimer.periodicity)
                        console.log(periodicity)

                        if (periodicity.typePeriodicity === 'weekly') {
                            this.type = 2
                            this.daytimes = periodicity.days
                            this.newOperationalTimerData.daytimes = {
                                days: periodicity.days
                            }
                            this.newOperationalTimerData.typeRepeat = 'weekly'
                        } else if (periodicity.typePeriodicity === 'daily') {
                            this.type = 1
                            this.daytimes = []
                            this.newOperationalTimerData.daytimes = {
                                days: periodicity.days
                            }
                            this.newOperationalTimerData.typeRepeat = 'daily'
                        } else if (periodicity.typePeriodicity === 'biweekly') {
                            this.type = 3
                            this.daytimes = periodicity.days
                            this.newOperationalTimerData.daytimes = {
                                days: periodicity.days
                            }
                            this.newOperationalTimerData.typeRepeat = 'biweekly'
                        }
                    }

                    // times

                    if (operationalTimer.times && Array.isArray(operationalTimer.times.t)) {
                        this.selectedTimeType = 2
                        // console.log('compuetedTime', operationalTimer.times)

                        this.specialTimes.t = operationalTimer.times.t
                        this.specialTimesFormated = operationalTimer.times.t.map((time) => this.convertToTimeFormat(time))
                    } else if (operationalTimer.times && operationalTimer.times.sd) {
                        this.selectedTimeType = 1
                        this.from_formatted = this.convertToTimeFormat(operationalTimer.times.sd)
                        this.to_formatted = this.convertToTimeFormat(operationalTimer.times.ed)
                        this.fixedTime.sd = operationalTimer.times.sd
                        this.fixedTime.ed = operationalTimer.times.ed
                        const intervalInHours = operationalTimer.times.iv / 60

                        if (Number.isInteger(intervalInHours)) {
                            this.fixedTime.iv = intervalInHours
                            this.selectedTimeUnit = 'hours'
                        } else {
                            this.fixedTime.iv = operationalTimer.times.iv
                            this.selectedTimeUnit = 'minutes'
                        }
                    } else {
                        this.newOperationalTimerData.times = {
                            sd: null,
                            ed: null,
                            iv: null
                        }
                    }

                    // register
                    if (operationalTimer.type == 1) {
                        this.newOperationalTimerData.type = 1
                    } else {
                        this.newOperationalTimerData.type = 2
                    }

                    if (operationalTimer.category_id) {
                        this.newOperationalTimerData.category_id = operationalTimer.category_id
                        this.newOperationalTimerData.category = operationalTimer.category_id
                        this.categorySelect = operationalTimer.category_id
                    } else {
                        this.newOperationalTimerData.category = ''
                        this.categorySelect = ''
                        this.newOperationalTimerData.category_id = ''
                    }

                    return this.newOperationalTimerData
                } else {
                    return this.newOperationalTimerData
                }
            } else {
                return this.newOperationalTimerData
            }
        },
        filteredColors() {
            return this.colors.filter((_, index) => index !== 16 && index !== 17)
        }
    },
    methods: {
        controlMinutesKeys(event, type) {
            const timeValue = this[type === 'start' ? 'from_formatted' : 'to_formatted']
            const [hours, minutes] = timeValue.split(':').map(Number)

            // Define los minutos permitidos
            const allowedMinutes = [0, 10, 15, 20, 30, 40, 45, 50]

            let newMinutes = minutes

            if (event.key === 'ArrowUp') {
                // Encuentra el siguiente valor permitido
                const nextIndex = (allowedMinutes.indexOf(minutes) + 1) % allowedMinutes.length
                newMinutes = allowedMinutes[nextIndex]
            } else if (event.key === 'ArrowDown') {
                // Encuentra el valor permitido anterior
                const prevIndex = (allowedMinutes.indexOf(minutes) - 1 + allowedMinutes.length) % allowedMinutes.length
                newMinutes = allowedMinutes[prevIndex]
            }

            // Ajusta la hora si es necesario
            let newHours = hours ? hours : 0
            if (newMinutes === 0 && minutes === 50 && event.key === 'ArrowUp') {
                newHours = (hours + 1) % 24
            } else if (newMinutes === 50 && minutes === 0 && event.key === 'ArrowDown') {
                newHours = (hours - 1 + 24) % 24
            }

            // Formatea el nuevo tiempo
            const adjustedTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`
            this[type === 'start' ? 'from_formatted' : 'to_formatted'] = adjustedTime
            event.preventDefault()
        },
        controlMinutesKeysFormatDifferent(event, index) {
            const timeValue = this.specialTimesFormated[index]
            const [hours, minutes] = timeValue.split(':').map(Number)

            // Definir los minutos permitidos
            const allowedMinutes = [0, 10, 15, 20, 30, 40, 45, 50]

            let newMinutes = minutes

            if (event.key === 'ArrowUp') {
                // Encuentra el siguiente valor permitido
                const nextIndex = (allowedMinutes.indexOf(minutes) + 1) % allowedMinutes.length
                newMinutes = allowedMinutes[nextIndex]
            } else if (event.key === 'ArrowDown') {
                // Encuentra el valor permitido anterior
                const prevIndex = (allowedMinutes.indexOf(minutes) - 1 + allowedMinutes.length) % allowedMinutes.length
                newMinutes = allowedMinutes[prevIndex]
            }

            // Ajustar la hora si es necesario
            let newHours = hours
            if (newMinutes === 0 && minutes === 50 && event.key === 'ArrowUp') {
                newHours = (hours + 1) % 24
            } else if (newMinutes === 50 && minutes === 0 && event.key === 'ArrowDown') {
                newHours = (hours - 1 + 24) % 24
            }

            // Formatear el nuevo tiempo
            const adjustedTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`
            this.$set(this.specialTimesFormated, index, adjustedTime)
            event.preventDefault()
        },
        marginInput() {
            !this.switchMargin
            this.item.margin = this.switchMargin == true ? 1 : false
            this.error.margin = false
        },
        weekdaySelect(daytime, isSelected) {
            if (isSelected) {
                this.daytimes = this.daytimes.filter((c) => {
                    return c !== daytime
                })
            } else {
                this.daytimes.push(daytime)
            }

            this.newOperationalTimerData.daytimes = {
                days: Object.values({ ...this.daytimes })
            }

            this.newOperationalTimerData.typeRepeat = this.periodicityTypes.find((item) => item.value === this.type).text
        },
        changeType(type) {
            this.daytimes = []
            if (type == 1) {
                this.daytimes = []
            } else if (type == 2 || type == 3) {
                this.daytimes = [0]
            } else this.monthSelect(0, type)

            this.newOperationalTimerData.typeRepeat = this.periodicityTypes.find((item) => item.value === type).text
            this.newOperationalTimerData.daytimes = {
                days: Object.values({ ...this.daytimes })
            }
        },
        convertToTimeFormat(decimalTime) {
            const hours = Math.floor(decimalTime)
            const minutes = Math.round((decimalTime - hours) * 60)
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
        },
        setSiteLimit(data) {
            this.newOperationalTimerData.groups = data.groups.split(',').map((item) => item.trim())
            this.newOperationalTimerData.locations = data.locations.split(',').map((item) => item.trim())
            this.limitmode = data.mode
            if (data.mode) {
                this.siteLimit = true
            }
        },
        async getCategories() {
            var self = this

            if (Object.values(self.$store.getters['operationalTimers/getCategories']()).length == 0) {
                await self.$store.dispatch('operationalTimers/loadCategoriesOperationalTimers')
            }

            const categoriesObject = this.$store.getters['operationalTimers/getCategories']()
            this.categories = Object.values(categoriesObject).map((category) => ({
                value: category.id,
                label: category.name
            }))
        },
        timeframeHoursFormat(type, event = false, index = null) {
            const getValue = (type, event) => {
                if (event) {
                    return event.target.value
                } else if (type === 'start') {
                    return this.selectedTimeType === 1 ? this.from_formatted : this.specialTimesFormated
                } else {
                    return this.to_formatted
                }
            }

            this.errors.times = false

            const parseTime = (timeStr) => {
                let [hourStr, minuteStr] = timeStr.split(':')
                return {
                    hour: parseInt(hourStr),
                    minutes: minuteStr ? parseFloat(minuteStr) / 60 : 0
                }
            }

            const formatMinutes = (minutes) => {
                if (minutes >= 0.9167) {
                    return { parsed: 0.0, formatted: '00', incrementHour: true }
                } else if (minutes >= 0.8) {
                    return { parsed: 0.8334, formatted: '50' }
                } else if (minutes >= 0.7166) {
                    return { parsed: 0.75, formatted: '45' }
                } else if (minutes >= 0.5833) {
                    return { parsed: 0.6667, formatted: '40' }
                } else if (minutes >= 0.4166) {
                    return { parsed: 0.5, formatted: '30' }
                } else if (minutes >= 0.3) {
                    return { parsed: 0.3334, formatted: '20' }
                } else if (minutes >= 0.2166) {
                    return { parsed: 0.25, formatted: '15' }
                } else if (minutes >= 0.1) {
                    return { parsed: 0.1667, formatted: '10' }
                } else {
                    return { parsed: 0.0, formatted: '00' }
                }
            }

            const formatTimeString = (hour, minutesFormatted) => {
                return `${hour.toString().padStart(2, '0')}:${minutesFormatted}`
            }

            let value = getValue(type, event)

            if (this.selectedTimeType === 2 && Array.isArray(value)) {
                value.forEach((time, i) => {
                    let { hour, minutes } = parseTime(time)
                    let { parsed, formatted, incrementHour } = formatMinutes(minutes)

                    if (incrementHour) hour += 1

                    const formattedTime = formatTimeString(hour, formatted)

                    if (type === 'start') {
                        this.$set(this.specialTimesFormated, i, formattedTime)
                        this.$set(this.specialTimes.t, i, hour + parsed)
                        this.specialTimes.t[i] =
                            this.from_mode < 0 && this.specialTimes.t[i] < 24 ? parseInt(this.from_mode) + this.specialTimes.t[i] : this.specialTimes.t[i]
                    }
                })
            } else if (index !== null) {
                let value = getValue(type, event)
                let { hour, minutes } = parseTime(value)
                let { parsed, formatted, incrementHour } = formatMinutes(minutes)

                if (incrementHour) hour += 1

                const formattedTime = formatTimeString(hour, formatted)

                this.$set(this.specialTimesFormated, index, formattedTime)
                this.$set(this.specialTimes.t, index, hour + parsed)
            } else {
                let { hour, minutes } = parseTime(value)
                let { parsed, formatted, incrementHour } = formatMinutes(minutes)

                if (incrementHour) hour += 1

                const formattedTime = formatTimeString(hour, formatted)

                if (type === 'start') {
                    this.from_formatted = formattedTime
                    this.fixedTime.sd = hour + parsed
                } else {
                    this.to_formatted = formattedTime
                    this.fixedTime.ed = hour + parsed
                }

                if (
                    this.from_mode === 24 ||
                    (parseInt(this.from_formatted.split(':')[0]) >= parseInt(this.to_formatted.split(':')[0]) &&
                        parseInt(this.to_formatted.split(':')[0]) >= 24)
                ) {
                    this.to_mode = 24
                }

                this.fixedTime.sd = this.from_mode < 0 && this.fixedTime.sd < 24 ? parseInt(this.from_mode) + this.fixedTime.sd : this.fixedTime.sd
                this.fixedTime.ed = this.from_mode < 0 && this.fixedTime.ed < 24 ? parseInt(this.from_mode) + this.fixedTime.ed : this.fixedTime.ed
            }
        },

        formatHours(tpl) {
            var template = { ...tpl }
            if (this.selectedTimeType === 1) {
                template.start_hour = tpl.times.sd && tpl.times.sd != null ? tpl.times.sd : tpl.times.sd
                template.end_hour = tpl.end_hour && tpl.end_hour != null ? tpl.times.ed : tpl.times.ed
                this.from_formatted = timeFormat(template.start_hour)

                this.to_formatted = timeFormat(template.end_hour)

                this.from_mode = parseInt('-' + Math.floor(Math.abs(template.start_hour) / 24) * 24)
                this.to_mode = Math.floor(Math.abs(template.end_hour) / 24) * 24
            }
            if (this.selectedTimeType === 2) {
                template.start_hour = tpl.times.t && tpl.times.t != null ? tpl.times.t : tpl.times.t
                template.start_hour.map((startHour) => this.specialTimesformatted.push(timeFormat(startHour)))
            }
        },
        validateOperationalTimers() {
            this.sendForm = true
            let timer = { ...this.newOperationalTimerData }

            //NAME
            if (timer.name === '') {
                this.sendForm = false
                this.errors.name = true
            } else {
                this.errors.name = false
            }
            //ORDER
            if (timer.order === '' || timer.order == 0) {
                this.sendForm = false
                this.errors.order = true
            }

            //LOCATION
            if (this.siteLimit) {
                const { locations, groups } = this.newOperationalTimerData

                if (locations.length > 0) {
                    timer.locations = locations
                } else if (groups.length > 0) {
                    timer.groups = groups
                } else if (locations.length <= 0) {
                    this.sendForm = false
                    this.errors.locations = true
                } else {
                    this.sendForm = false
                    this.errors.groups = true
                }
            } else {
                this.newOperationalTimerData.groups = []
                this.newOperationalTimerData.locations = []
                timer.locations = []
                timer.groups = []
            }

            // Validación de la categoría
            if (!timer.category_id || timer.category_id === '') {
                this.sendForm = false
                this.errors.category = true
            }

            //CONFIG
            if (this.selectedTimeType == 1 && (!timer.times.sd || !timer.times.ed || !timer.times.iv)) {
                this.sendForm = false
                this.errors.times = true
            } else if (this.selectedTimeType == 2 && timer.times.t.length === 0) {
                this.sendForm = false
                this.errors.times = true
            } else {
                this.errors.times = false
            }
            return this.sendForm
        },

        newTranslation(code) {
            this.$set(this.newOperationalTimerData.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.newOperationalTimerData.translations, code)
        },

        // Method for make changes from form to store object
        updateValues(v) {
            this.newOperationalTimerData = this.newOperationalTimerData
            this.errors.order = false
            this.errors.iv = false
        },
        //Put the limitation for input 50 letters, count icon as one letter
        handleInput(event) {
            this.newOperationalTimerData.name = event

            // Regular expression to match icons (assuming icons are represented as :icon_name:)
            const iconRegex = /:\w+:/g
            const iconMatches = this.newOperationalTimerData.name.match(iconRegex)

            // Replace icons with a placeholder (e.g., '##') to measure the length correctly
            const cleanedName = this.newOperationalTimerData.name.replace(iconRegex, '##')

            // Calculate the total length considering icons as 1 character each
            const totalLength = cleanedName.length + (iconMatches ? iconMatches.length : 0)

            this.errors.name = false

            // Set error if total length exceeds 50 characters
            this.nameTooLong = totalLength > 50
            if (this.nameTooLong) {
                this.errors.nameTooLong = true
            } else {
                this.errors.nameTooLong = false
            }
        },
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        popupStatus() {
            var self = this

            if (self.newOperationalTimerData.status == '1' || self.newOperationalTimerData.status == 1) {
                self.$popup.confirm({
                    message: self.$t('tools.operationalTimer.status_description'),
                    textSave: self.$t('product.product_state.state_save'),
                    callSave: function () {
                        self.newOperationalTimerData.status = 0
                        self.updateValues(self.newOperationalTimerData.status)
                    },
                    callCancel: function () {
                        self.newOperationalTimerData.status = 1
                        self.updateValues(self.newOperationalTimerData.status)
                    }
                })
            } else {
                self.newOperationalTimerData.status = 1
                self.updateValues(self.newOperationalTimerData.status)
            }
        },
        siteLimitSwitch() {
            if (this.siteLimit == false) {
                this.siteLimit = true
            } else {
                this.siteLimit = false
            }
        },
        registerSwitch() {
            if (this.newOperationalTimerData.type == 1) {
                this.newOperationalTimerData.type = 2
            } else {
                this.newOperationalTimerData.type = 1
            }
        },
        generateIconUrls() {
            const baseUrl = 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_'
            for (let i = 1; i <= 40; i++) {
                const iconNumber = String(i).padStart(2, '0') // Ensures two-digit format
                this.iconUrls.push(`${baseUrl}${iconNumber}.svg`)
            }
        },
        toggleIconDropdown() {
            this.showAllIcons = !this.showAllIcons
        },
        setIcon(iconUrl) {
            this.newOperationalTimerData.icon = iconUrl
            this.showAllIcons = false
        },
        toggleColorDropdown() {
            this.showAllColors = !this.showAllColors
        },
        setColor(color) {
            this.$set(this.newOperationalTimerData, 'color', color)
            this.showAllColors = false
        },
        setCategory(category) {
            this.newOperationalTimerData.category_id = category
            this.errors.category = false
        },
        updateInterval(value) {
            let intervalValue = parseFloat(value)
            const hoursText = 'hours'
            const minutesText = 'minutes'

            if (intervalValue) {
                this.errors.times = false
            }

            if (this.selectedTimeUnit == hoursText) {
                this.fixedTime.iv = intervalValue * 60
            } else if (this.selectedTimeUnit == minutesText) {
                this.fixedTime.iv = intervalValue
            } else {
                this.fixedTime.iv = intervalValue * 60
            }
        },
        load() {
            this.getCategories()
            var self = this
            // Load form on refresh
            if (Object.values(self.$store.getters['operationalTimers/getOperationalTimers']()).length == 0) {
                self.$store.dispatch('operationalTimers/loadOperationalTimers', {}).then(() => {
                    self.routeHandling()
                })
            } else {
                self.routeHandling()
            }
        },

        routeHandling() {
            var self = this
            if (self.$route.name == 'OperationalTimers') {
                if (typeof self.id != 'undefined') {
                    self.idOperationalTimer = self.id
                    self.$router.push({
                        name: 'EditOperationalTimers',
                        params: { id: self.id }
                    })

                    self.formatHours(self.newOperationalTimerData)
                } else {
                    self.$store.dispatch('operationalTimers/addNewOperationalTimer').then((id) => {
                        self.idOperationalTimer = id
                        self.$router.push({ name: 'AddOperationalTimers' })
                        self.formatHours(self.newOperationalTimerData)
                    })
                }
            } else {
                if (typeof self.id != 'undefined') {
                    self.idOperationalTimer = self.id
                    self.formatHours(self.newOperationalTimerData)
                } else {
                    self.$store.dispatch('operationalTimers/addNewOperationalTimer').then((id) => {
                        self.idOperationalTimer = id
                        self.formatHours(self.newOperationalTimerData)
                    })
                }
            }
        },
        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveOperationalTimer() {
            const self = this
            this.$overlay.loading()

            const action = this.id ? 'editOperationalTimer' : 'addOperationalTimer'

            if (this.newOperationalTimerData.status == 0) {
                this.newOperationalTimerData.status = -2
            }

            // Ensure default icon if not selected
            if (!this.newOperationalTimerData.icon) {
                this.newOperationalTimerData.icon = this.iconUrls[24] // Default icon
            }

            // Ensure default color if not selected
            if (!this.newOperationalTimerData.color) {
                this.newOperationalTimerData.color = this.colors[15] // Default color
            }

            // Update locations and groups before validation and store dispatch
            if (typeof this.newOperationalTimerData.locations === 'string') {
                this.newOperationalTimerData.locations = this.newOperationalTimerData.locations.split(',').map((item) => item.trim())
            }

            if (typeof this.newOperationalTimerData.groups === 'string') {
                this.newOperationalTimerData.groups = this.newOperationalTimerData.groups.split(',').map((item) => item.trim())
            }
            if (!this.newOperationalTimerData.typeRepeat) {
                this.newOperationalTimerData.typeRepeat = this.periodicityTypes.find((item) => item.value === 1).text
            }
            //set the unit of interval if is fixed hour
            if (this.selectedTimeType === 1) {
                this.updateInterval(this.fixedTime.iv)

                this.newOperationalTimerData.times = this.fixedTime
            } else {
                this.newOperationalTimerData.times = this.specialTimes
            }
            if (this.selectedTimeType === 2) {
                let timeSet = new Set() // Use a set to track unique times
                let filteredTimes = []
                let filteredFormattedTimes = []
                console.log('timesForFilter', this.newOperationalTimerData.times.t)
                this.newOperationalTimerData.times.t.forEach((time, index) => {
                    if (time !== '' && !timeSet.has(time)) {
                        timeSet.add(time)
                        filteredTimes.push(time)
                        filteredFormattedTimes.push(this.specialTimesFormated[index])
                    }
                })

                this.newOperationalTimerData.times.t = filteredTimes
                this.specialTimes.t = filteredTimes
                this.specialTimesFormated = filteredFormattedTimes
            }
            // Validate operational timers

            this.newOperationalTimerData.daytimes.typePeriodicity = this.newOperationalTimerData.typeRepeat

            if (this.validateOperationalTimers()) {
                this.newOperationalTimerData.times = JSON.stringify(self.newOperationalTimerData.times)
                this.newOperationalTimerData.periodicity = JSON.stringify(self.newOperationalTimerData.daytimes)

                console.log('datatoBack', this.newOperationalTimerData)
                this.$store
                    .dispatch('operationalTimers/' + action, this.newOperationalTimerData)
                    .then(() => {
                        this.goBack()
                        this.$snackbar.open({
                            message: this.id
                                ? this.$t('snackbar.edit_success') + ' <b>' + this.newOperationalTimerData.name + '</b>'
                                : this.$t('snackbar.add_success') + ' <b>' + this.newOperationalTimerData.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                    .catch((error) => {
                        console.error('Error saving operational timer:', error)
                        this.$overlay.hide()
                    })
            } else {
                this.$overlay.hide()
            }
        },

        deleteOperationalTimer() {
            this.newOperationalTimerData.status = -1
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.newOperationalTimerData.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store
                .dispatch('operationalTimers/removeOperationalTimer', self.newOperationalTimerData.id)
                .then(() => {
                    self.$emit('back')
                    self.$snackbar.open(snackbarData)
                })
                .catch((error) => {
                    console.error('There was an error dispatching the delete operation:', error)
                })
        },

        showDeleteOperationalTimerModal() {
            var self = this
            const modalMessage = self.$t('tools.operationalTimer.delete', { name: self.newOperationalTimerData.name })

            this.$popup.delete({ message: modalMessage, callSave: this.deleteOperationalTimer, customClass: 'timer-delete-popup' })
        },
        clone() {
            const self = this
            this.$popup.confirm({
                message: this.$t('product.popup_clone'),
                textSave: this.$t('product.popup_button_clone'),
                callSave: async () => {
                    self.$overlay.loading()

                    // Update locations and groups before validation and store dispatch
                    if (typeof this.newOperationalTimerData.locations === 'string') {
                        this.newOperationalTimerData.locations = this.newOperationalTimerData.locations.split(',').map((item) => item.trim())
                    }

                    if (typeof this.newOperationalTimerData.groups === 'string') {
                        this.newOperationalTimerData.groups = this.newOperationalTimerData.groups.split(',').map((item) => item.trim())
                    }
                    //set the unit of interval if is fixed hour
                    if (this.selectedTimeType === 1) {
                        this.updateInterval(this.fixedTime.iv)

                        this.newOperationalTimerData.times = this.fixedTime
                    } else {
                        this.newOperationalTimerData.times = this.specialTimes
                    }
                    let timer = self.validateOperationalTimers()

                    if (self.sendForm) {
                        this.newOperationalTimerData.times = JSON.stringify(self.newOperationalTimerData.times)
                        await self.$store.dispatch('operationalTimers/editOperationalTimer', { ...timer })
                        await self.$store.dispatch('operationalTimers/cloneTimer', { id: self.id })
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.clone_success') + ' <b>' + self.newOperationalTimer.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    } else {
                        self.$overlay.hide()
                        scrollError()
                    }
                },
                callCancel: () => self.$popup.close()
            })
        },
        addTime() {
            this.specialTimes.t.push('')
            this.specialTimesFormated.push('')
        },
        deleteSpecialTime(index) {
            this.specialTimes.t.splice(index, 1)
            this.specialTimesFormated.splice(index, 1)
        }
    },

    created() {
        this.getCategories()
        this.generateIconUrls()
        this.load()
    },
    mounted() {},
    updated() {}
}
</script>

<style lang="scss">
.daytime {
    width: 100%;
    // margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 12px;
    gap: 8px;
    flex-wrap: wrap;
    .item {
        @extend .interaction;
        // @include font-size(ml);
        @include font-size(sm);
        background-color: $color-neutral-200;
        padding: 1px 9px;
        width: fit-content;
        display: inline-block;
        // margin-top: 9px;
        // margin-right: 6px;
        border-radius: 3px;
        color: $color-black;
        font-family: $text;
        // height: 30px;

        &.month {
            @include text-ellipsis();
            @include font-size(md);
            line-height: 30px;
            text-align: center;
            width: calc(25% - 9px);
            overflow: hidden;
            margin-right: 9px;
        }

        &.selected {
            color: #fff;
            background-color: $color-primary-500;
        }
    }
    &.error {
        @include border-radius(6px);
        @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
        padding: 0 30px 9px 9px;
        border: 1px solid $color-error-500;
    }
}

#content.operational-timer-management {
    .left-container {
        padding-left: 0;
    }

    .icon,
    .dropdown {
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 4px;
    }
    .square {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .defaultBall {
        margin: 0;
    }

    .ball {
        margin: 5px 5px 5px 0;
    }

    .ball,
    .defaultBall {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
    }
    .icon {
        width: 30px;
        height: 30px;
    }
    .dropdown {
        .icon {
            margin: 4px;
        }
    }
    .timeType1,
    .timeType2Input,
    .interval_line {
        display: flex;
        gap: 4px;
        margin-bottom: 4px;
        align-items: center;
    }
    .interval_line {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .interval {
        width: 45px;
        padding: 6px;
        font-size: 14px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
    }
    .v-select__slot {
        color: $color-neutral-600;
    }
    .opt-group {
        .radio {
            display: flex;
        }
        .option {
            .label {
                color: $color-neutral-600;
                font-weight: 300;
                padding-left: 0px;
            }
        }

        .addTime {
            background-color: $color-primary-700;
            margin: 8px 0 0 0;
            &:hover {
                background-color: $color-primary-800;
            }
        }
    }
    .disabled {
        cursor: not-allowed;
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details {
        display: none;
    }

    .v-input__slot {
        margin-bottom: 0px;
    }
    .v-input__control {
        min-height: 0;
    }

    .timeUnitsSelect {
        width: 134px;
    }
    .categorySelect {
        width: 100%;
    }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 30px;
    }

    .deleteTime {
        @include background($size: 14px, $image: img('delete_light.svg'), $position: center);
        @include display-flex();
        background-color: $color-error-500;
        width: 20px;
        height: 20px;
        float: right;
        cursor: pointer;
        border-radius: 4px;
    }

    .typeRepeat {
        display: flex;
        align-items: center;
    }

    .wtimer-90 {
        width: 90% !important;
    }
}
</style>
